import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: async () => import('@app/pages/app.component').then((routes: any) => routes.AppComponent),
    children: [
      {
        path: '',
        loadComponent: async () => import('@app/pages/home/home.component').then((routes: any) => routes.HomeComponent),
      },
    ],
  },
  {
    path: 'signin',
    loadComponent: async () => import('@app/auth/signin/signin.component').then((routes: any) => routes.SigninComponent),
  },
  {
    path: 'signup',
    loadComponent: async () => import('@app/auth/signup/signup.component').then((routes: any) => routes.SignupComponent),
  },
  {
    path: 'reset',
    loadComponent: async () => import('@app/auth/reset/reset.component').then((routes: any) => routes.ResetComponent),
  },
  {
    path: 'confirm',
    loadComponent: async () => import('@app/auth/confirm/confirm.component').then((routes: any) => routes.ConfirmComponent),
  },
  {
    path: 'error',
    loadComponent: async () => import('@app/error/error.component').then((routes: any) => routes.ErrorComponent),
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ROUTES {}
